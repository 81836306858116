<!--
 * @Author: wch
 * @Date: 2021-11-17 15:18:54
 * @LastEditTime: 2021-12-14 16:16:44
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \aiparkcity-acb\src\views\parkingBerthUtilization\lineChart.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <div id="myChart" :style="{ height: '300px' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    lineChartLabel: {
      default: function () {
        return [];
      },
    },
    lineChartValue: {
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    this.initChart();
  },
  watch: {
    lineChartLabel: {
      handler(ne) {
        this.updatedData();
      },
    },
  },
  methods: {
    updatedData() {
      this.myChart.clear();
      this.myChart.setOption({
        tooltip: {
          trigger: "axis",
          formatter: (parmas) => {
            console.log(this.$parent.getDateType(), "parkingBerthUtilization");

            let str = "";
            if (this.$parent.getDateType() == 1) {
              str += parmas[0].axisValue + " 00:00:00-23:59:59<br/>";
            } else {
              str += parmas[0].axisValue + "<br/>";
            }

            parmas.forEach((e) => {
              str += `${e.marker} ${e.seriesName}: ${(e.value * 100).toFixed(2)}%<br/>`;
            });
            return str;
          },
        },
        xAxis: {
          data: this.lineChartLabel,
        },
        yAxis: {
          name: "泊位利用率",
          min: 0,
          max: 1,
          interval: 0.1,
          axisLabel: {
            formatter: function (value) {
              return value * 100 + "%";
            },
          },
        },
        series: [
          {
            name: "泊位利用率",
            type: "line",
            data: this.lineChartValue,
          },
        ],
      });
    },
    initChart() {
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      this.myChart = myChart;
      this.updatedData();
    },
  },
};
</script>
